import { generateTwVariants } from "tailwind-config";
import { tv } from "tailwind-variants";

export const typographyVariant = tv({
  base: "tw-relative tw-break-all",
  variants: {
    color: generateTwVariants("colors", "text"),
    size: generateTwVariants("fontSize", "text"),
    underline: {
      true: "tw-underline",
      false: ""
    },
    display: {
      block: "tw-block",
      inline: "tw-inline",
      "inline-block": "tw-inline-block",
      flex: "tw-flex",
      "inline-flex": "tw-inline-flex"
    },
    mark: {
      true: ""
    },
    fontWeight: {
      thin: "tw-font-thin",
      extralight: "tw-font-extralight",
      light: "tw-font-light",
      normal: "tw-font-normal",
      medium: "tw-font-medium",
      semibold: "tw-font-semibold",
      bold: "tw-font-bold",
      extrabold: "tw-font-extrabold"
    }
  }
});
