export const colors = {
  white: "#ffffff",
  transparent: "transparent",

  gray100: "#F7F8F9",
  gray200: "#E9EBEE",
  gray300: "#C5C8CE",
  gray400: "#9DA3AE",
  gray500: "#646F7C",
  gray600: "#374553",
  gray700: "#000000",

  yellow100: "#FFF5E5",
  yellow500: "#FF9600",

  green100: "#E8FFE5",
  green500: "#14D700",

  socarElecleBlue100: "#E5F4FF",
  socarElecleBlue200: "#C5E5FF",
  socarElecleBlue500: "#008CFF",
  socarElecleBlue600: "#0081EB",

  red100: "#FFE8E5",
  red500: "#FF4632"
} as const;
