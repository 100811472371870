export const fontSize = {
  heading1: [
    "24px",
    {
      lineHeight: "36px",
      fontWeight: "700"
    }
  ],
  heading2: [
    "20px",
    {
      lineHeight: "28px",
      fontWeight: "700"
    }
  ],
  title1: [
    "18px",
    {
      lineHeight: "26px",
      fontWeight: "700"
    }
  ],
  title2: [
    "16px",
    {
      lineHeight: "23px",
      fontWeight: "700"
    }
  ],
  title3: [
    "14px",
    {
      lineHeight: "20px",
      fontWeight: "700"
    }
  ],
  body1: [
    "20px",
    {
      lineHeight: "28px",
      fontWeight: "400"
    }
  ],
  body2: [
    "18px",
    {
      lineHeight: "26px",
      fontWeight: "400"
    }
  ],
  body3: [
    "16px",
    {
      lineHeight: "23px",
      fontWeight: "400"
    }
  ],

  body4: [
    "14px",
    {
      lineHeight: "20px",
      fontWeight: "400"
    }
  ],
  body5: [
    "12px",
    {
      lineHeight: "17px",
      fontWeight: "400"
    }
  ]
} as const;
