import plugin from "tailwindcss/plugin";

export const scrollbarHide = plugin(({ addUtilities }) => {
  addUtilities({
    ".scrollbar-hide": {
      "scrollbar-width": "none",
      "-ms-overflow-style": "none",
      "&::-webkit-scrollbar": {
        display: "none"
      }
    }
  });
});

