import { scrollbarHide, translateHalf } from "./custom-plugins";
import {
  borderRadius,
  borderWidth,
  boxShadow,
  colors,
  fontFamily,
  fontSize,
  spacing
} from "./tokens";

const config = {
  prefix: "tw-",
  content: [
    "./app/**/*.{js,ts,jsx,tsx,mdx}",
    "./pages/**/*.{js,ts,jsx,tsx,mdx}",
    "./components/**/*.{js,ts,jsx,tsx,mdx}",
    "./src/**/*.{js,ts,jsx,tsx}"
  ],
  theme: {
    colors,
    spacing,
    fontSize,
    fontFamily,
    borderWidth,
    borderRadius,
    boxShadow,
    screens: {
      vertical_small: { raw: "(max-height: 400px)" },
      desktop: { raw: "(max-width: 1440px)" },
      tablet: { raw: "(max-width: 768px)" },
      mobile: { raw: "(max-width: 645px)" },
      fold: { raw: "(max-width: 320px)" }
    },
    extends: {
      whitespace: {
        "pre-line": "pre-line"
      }
    }
  },
  corePlugins: {
    preflight: false
  },
  plugins: [scrollbarHide, translateHalf],
  safelist: [
    ...Object.keys(colors).map((key) => `tw-text-${key}`),
    ...Object.keys(fontSize).map((key) => `tw-text-${key}`)
  ]
} as const;

export type TailwindConfig = typeof config;

export type KeyOfTheme<T extends keyof TailwindConfig["theme"]> =
  keyof TailwindConfig["theme"][T];

export type ValueOfTheme<T extends keyof TailwindConfig["theme"]> =
  TailwindConfig["theme"][T][KeyOfTheme<T>];

export default config;
