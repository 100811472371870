import { type FontSize, type Color } from "tailwind-config";

import { typographyVariant } from "./typographyVariant";

export type TypographyProps = {
  variant: FontSize;
  color?: Color;
  className?: string;
  style?: React.CSSProperties;
  onClick?: (e: React.MouseEventHandler) => void;
  as?: React.ElementType;
  children: React.ReactNode;
  underline?: boolean;
  backgroundColor?: string;
  fontWeight?:
    | "thin"
    | "extralight"
    | "light"
    | "normal"
    | "medium"
    | "semibold"
    | "bold"
    | "extrabold";
};
export const Typography = ({
  children,
  variant,
  color,
  className,
  style,
  onClick,
  as = "span",
  underline,
  backgroundColor,
  fontWeight,
  ...props
}: TypographyProps) => {
  const Tag = as || "span";

  const background =
    as === "mark" && backgroundColor
      ? `linear-gradient(
    to bottom, transparent 67%,
  ${backgroundColor} 50%
)`
      : backgroundColor;

  return (
    <Tag
      className={`${typographyVariant({
        size: variant,
        color: color,
        underline,
        mark: as === "mark",
        fontWeight: fontWeight,
        className
      })}`}
      style={{
        ...style,
        background
      }}
      onClick={onClick}
      {...props}
    >
      {children}
    </Tag>
  );
};
